// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../styleguide/components/Heading/H1.res.js";
import * as H2 from "../../../../../styleguide/components/Heading/H2.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Breadcrumb from "../../../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_ProviderBrochure from "../../../../../api/provider-brochures/Api_ProviderBrochure.res.js";
import * as SignInModalContainer from "../../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as Routes_ProviderBrochure from "../../../../../routes/common/Routes_ProviderBrochure.res.js";
import * as ProviderBrochureShowContentScss from "./ProviderBrochureShowContent.scss";

var css = ProviderBrochureShowContentScss;

var initialState = {
  showSignIn: false
};

function reducer(state, action) {
  return {
          showSignIn: !state.showSignIn
        };
}

function ProviderBrochureShowContent(props) {
  var userLoginStatus = props.userLoginStatus;
  var show = props.show;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var tmp;
  tmp = props.userRole === "Admin" ? JsxRuntime.jsx(Breadcrumb.make, {
          breadcrumbLinks: [
            {
              linkName: "Home",
              linkPath: "/"
            },
            {
              linkName: "All Provider Brochures",
              linkPath: Routes_ProviderBrochure.index
            },
            {
              linkName: show.providerBrochure.title,
              linkPath: Routes_ProviderBrochure.show(show.providerBrochure.slug)
            },
            {
              linkName: "Edit",
              linkPath: Routes_ProviderBrochure.Dashboard.edit(show.providerBrochure.id)
            }
          ],
          wrapperContainerClassName: css.breadcrumbs
        }) : JsxRuntime.jsx(Breadcrumb.make, {
          breadcrumbLinks: [
            {
              linkName: "Home",
              linkPath: "/"
            },
            {
              linkName: "All Provider Brochures",
              linkPath: Routes_ProviderBrochure.index
            },
            {
              linkName: show.providerBrochure.title,
              linkPath: Routes_ProviderBrochure.show(show.providerBrochure.slug)
            }
          ],
          wrapperContainerClassName: css.breadcrumbs
        });
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Provider Brochure: " + show.provider.name,
                              className: css.sectionTitle
                            }),
                        JsxRuntime.jsx(H1.make, {
                              className: css.title,
                              children: show.providerBrochure.title
                            }),
                        JsxRuntime.jsx(H2.make, {
                              className: css.subtitle,
                              children: show.providerBrochure.subtitle
                            })
                      ],
                      className: css.heading
                    }),
                JsxRuntime.jsx("div", {
                      className: css.description,
                      dangerouslySetInnerHTML: {
                        __html: show.providerBrochure.description
                      }
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "MD",
                            color: "Teal",
                            disabled: show.providerBrochure.pdfFile === "",
                            visuallyDisabled: show.providerBrochure.pdfFile.length === 0,
                            onClick: (function (param) {
                                if (userLoginStatus === "LoggedIn") {
                                  var providerBrochure = show.providerBrochure;
                                  return $$Promise.wait(Api_ProviderBrochure.notifyDownload(providerBrochure.id), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return Url.visitBlank(providerBrochure.privateDownload);
                                                } else {
                                                  return SentryLogger.error1({
                                                              rootModule: "ProviderBrochureShowContent",
                                                              subModulePath: /* [] */0,
                                                              value: "make",
                                                              fullPath: "ProviderBrochureShowContent.make"
                                                            }, "ProviderBrochureShowContent" + "::ProviderBrochure::notifyDownload::Error", [
                                                              "Error",
                                                              x._0
                                                            ]);
                                                }
                                              }));
                                } else {
                                  return dispatch("ToggleSignInModal");
                                }
                              }),
                            children: "Download Brochure"
                          }),
                      className: css.download
                    }),
                match[0].showSignIn ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            dispatch("ToggleSignInModal");
                          }),
                        callback: (function () {
                            
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign in to download a Location Brochure."
                      }) : null
              ],
              className: css.content
            });
}

var make = ProviderBrochureShowContent;

export {
  css ,
  initialState ,
  reducer ,
  make ,
}
/* css Not a pure module */
