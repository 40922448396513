// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Picture from "../../../../../styleguide/forms/Picture/Picture.res.js";
import * as Globals from "Common/globals";
import * as IconUserAvatar from "../../../../../styleguide/icons/IconUserAvatar.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import Message_sentPng from "./images/message_sent.png";
import * as ProviderBrochureContactForm from "./components/ProviderBrochureContactForm.res.js";
import * as ProviderBrochureShowContactScss from "./ProviderBrochureShowContact.scss";

var css = ProviderBrochureShowContactScss;

var messageSent = Message_sentPng;

var initialState = {
  submitted: false
};

function getProfileImage(image, cloudName) {
  return "https://res.cloudinary.com/" + cloudName + "/image/upload/c_scale,w_600/c_crop,g_face,h_600,r_max,w_600/c_scale,w_405/" + image;
}

function ProviderBrochureShowContact(props) {
  var provider = props.provider;
  var reducer = function (_state, action) {
    return {
            submitted: action._0
          };
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  if (match[0].submitted) {
    return JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Picture.make, {
                              src: messageSent,
                              large: [
                                90,
                                70
                              ],
                              crop: "Fit",
                              imageClassName: css.envelopeImage
                            }),
                        className: css.providerContactEnvelope
                      }),
                  JsxRuntime.jsx("div", {
                        children: "Your Message Has Been Sent!",
                        className: css.providerContactMessage
                      }),
                  JsxRuntime.jsx("div", {
                        children: "Expect a response within 1 business day.",
                        className: css.providerContactMessageSub
                      })
                ],
                className: css.providerContactSubmitted
              });
  }
  var x = provider.salesManager;
  var tmp;
  if (x !== undefined) {
    var profileImage = getProfileImage(x.image, Globals.CloudinaryCloudName);
    var match$1 = x.image;
    var tmp$1 = match$1 === "" ? JsxRuntime.jsx(IconUserAvatar.make, {
            size: "XXL",
            className: css.defaultProfilePic
          }) : JsxRuntime.jsx("img", {
            className: css.salesManagerImage,
            alt: "Profile Image",
            src: profileImage
          });
    tmp = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx("div", {
                  children: tmp$1,
                  className: css.providerContactPersonImage
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsx("div", {
                          children: x.name,
                          className: css.providerContactName
                        }),
                    JsxRuntime.jsx("div", {
                          children: x.title,
                          className: css.providerContactJobTitle
                        })
                  ],
                  className: css.providerContactSummary
                })
          ],
          className: css.providerContactPerson
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Contact " + provider.name,
                              className: css.providerContactTitle
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                "Complete the form to get in ",
                                JsxRuntime.jsx("strong", {
                                      children: "direct contact with " + provider.name + "."
                                    })
                              ],
                              className: css.providerContactSubtitle
                            })
                      ],
                      className: css.providerContactHeader
                    }),
                tmp,
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ProviderBrochureContactForm.make, {
                            provider: provider,
                            setSubmitted: (function (value) {
                                dispatch({
                                      TAG: "SetSubmitted",
                                      _0: value
                                    });
                              })
                          }),
                      className: css.providerContactForm
                    })
              ],
              className: css.providerContactContainer
            });
}

var make = ProviderBrochureShowContact;

export {
  css ,
  messageSent ,
  initialState ,
  getProfileImage ,
  make ,
}
/* css Not a pure module */
