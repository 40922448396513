import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from '../../../../bundles/Client/common/actions/userActions';

import ProviderBrochureShow from './ProviderBrochureShow.res.js';

const mapStateToProps = state => ({
  reduxProps: {
    isUserLoggedIn: !!state.user.id,
  },
});

const mapDispatchToProps = dispatch => ({
  reduxfunction: {
    setUserData: userData => {
      dispatch(setUserData(userData));
    },
  },
});

const ProviderBrochureShowContainer = connect(mapStateToProps, mapDispatchToProps)(ProviderBrochureShow);

export default ({ context, ...props }) => (
  <ReduxProvider {...props}>
    <ProviderBrochureShowContainer props={props} context={context} />
  </ReduxProvider>
);
