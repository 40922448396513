// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Role from "../../../models/Role.res.js";
import * as User from "../../../models/User.res.js";
import * as ProviderBrochure from "../../../models/ProviderBrochure.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderBrochureShowScss from "./ProviderBrochureShow.scss";
import * as ProviderBrochureShowContact from "./components/contact/ProviderBrochureShowContact.res.js";
import * as ProviderBrochureShowContent from "./components/content/ProviderBrochureShowContent.res.js";
import * as ProviderBrochureShowSidebar from "./components/sidebar/ProviderBrochureShowSidebar.res.js";

var css = ProviderBrochureShowScss;

function ProviderBrochureShow$ProviderBrochureShow(props) {
  var show = props.show;
  var userRole = Role.fromString(props.userRole);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ProviderBrochureShowSidebar.make, {
                            show: show
                          }),
                      className: css.sidebarContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ProviderBrochureShowContent.make, {
                            show: show,
                            userLoginStatus: props.userLoginStatus,
                            userRole: userRole,
                            setUserData: props.setUserData
                          }),
                      className: css.mainContentContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ProviderBrochureShowContact.make, {
                            provider: show.provider
                          }),
                      className: css.contactUsContainer
                    })
              ],
              className: css.container
            });
}

var ProviderBrochureShow = {
  css: css,
  make: ProviderBrochureShow$ProviderBrochureShow
};

function ProviderBrochureShow$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsx(ProviderBrochureShow$ProviderBrochureShow, {
              show: ProviderBrochure.Show.fromJs(props$1.show),
              userLoginStatus: User.LoginStatus.fromBool(props.reduxProps.isUserLoggedIn),
              setUserData: props.reduxfunction.setUserData,
              userRole: props$1.userRole
            });
}

var $$default = ProviderBrochureShow$default;

export {
  ProviderBrochureShow ,
  $$default as default,
}
/* css Not a pure module */
