// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as IconWeb from "../../../../../styleguide/icons/IconWeb.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderBrochureShowSidebarScss from "./ProviderBrochureShowSidebar.scss";

var css = ProviderBrochureShowSidebarScss;

function ProviderBrochureShowSidebar(props) {
  var show = props.show;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("img", {
                            src: show.providerBrochure.image
                          }),
                      className: css.imageContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Provider",
                              className: css.summaryTitle
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("span", {
                                      children: JsxRuntime.jsx(IconWeb.make, {
                                            size: "SM",
                                            color: "DarkerGray"
                                          }),
                                      className: css.icon,
                                      id: "providerIcon"
                                    }),
                                JsxRuntime.jsx("span", {
                                      children: JsxRuntime.jsx(A.make, {
                                            id: "providerLink",
                                            href: Routes_Provider.show(show.provider.slug),
                                            className: css.detailLink,
                                            children: show.provider.name
                                          }),
                                      className: css.name,
                                      id: "providerName"
                                    })
                              ],
                              className: css.providerDetail
                            })
                      ],
                      className: css.providerSummary
                    })
              ],
              className: css.sidebar
            });
}

var make = ProviderBrochureShowSidebar;

export {
  css ,
  make ,
}
/* css Not a pure module */
